 <template>
  <!-- <v-col class="loginContainer"> -->
  <v-row style="min-height: 100vh" class="loginContainer">
    <v-col align="center" class="align-self-center">
      <v-form style="max-width: 450px !important">
        <v-container>
          <v-row>
            <v-col align="center">
              <v-img
                :src="require('@/assets/general/logoLogin.png')"
                width="120"
                height="110"
              />
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <span class="textFontDecoration textDecorationBold font25">
                REALTOR
              </span>
              <span
                class="textFontDecoration font25"
                style="font-style: CenturyGothic, sans-serif"
              >
                VOICE
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="username"
                :placeholder="`${$t('username')}...`"
                prepend-inner-icon="mdi-account-outline"
                required
                hide-details
                v-on:keyup.enter="onLoginHandler"
                class="textFontDecoration"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                hide-details
                v-model="password"
                :placeholder="`${$t('password')}`"
                hint="At least 8 characters"
                prepend-inner-icon="mdi-lock-outline"
                class="input-group--focused textFontDecoration"
                v-on:keyup.enter="onLoginHandler"
                @click:append="show = !show"
              />
            </v-col>
          </v-row>
          <!-- <v-row>
              <v-col>
                <v-checkbox
                  v-model="checkbox"
                  color="primary"
                  hide-details
                  class="mr-2 textFontDecoration checkboxAg"
                  :label="$t('stayConnected')"
                />
              </v-col>
            </v-row> -->
          <v-row>
            <v-col xs="12">
              <v-btn
                large
                depressed
                color="primary"
                elevation="5"
                :loading="loading"
                @click="onLoginHandler"
                class="textDecorationBold btnContainer"
                height="55"
                width="200"
              >
                {{ $t("login") }}
              </v-btn>
            </v-col>
            <!-- <v-col xs="12">
              <v-btn
                large
                outlined
                color="primary"
                class="textDecorationBold btnContainer"
                @click="goToRegister"
                height="55"
                width="200"
              >
                {{ $t("register") }}
              </v-btn>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col align="center">
              <u>
                <a class="forgetPass" @click="goToForgot">
                  {{ $t("forgotPass") }}
                </a>
              </u>
            </v-col>
          </v-row>
          <v-row class="mt-10 mb-5" align="center">
            <v-col> <v-divider /> </v-col>
            <v-col class="flex-grow-0">
              <span> {{ $t("or") }} </span>
            </v-col>
            <v-col> <v-divider /> </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <v-btn
                large
                outlined
                color="primary"
                class="textDecorationBold btnContainerFullWidth"
                @click="googleSignIn"
                height="55"
                max-width="450"
                min-width="200"
              >
                <v-row>
                  <v-col class="flex-grow-0 align-self-center">
                    <v-img :src="require('@/assets/general/googleLogo.svg')" />
                  </v-col>
                  <v-col class="align-self-center" cols="9">
                    <span> {{ $t("loginWithGoogle") }} </span>
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <v-btn
                large
                depressed
                class="textDecorationBold btnContainerFullWidth btnBlack"
              >
                <v-row>
                  <v-col class="align-self-center flex-grow-0">
                    <v-icon dark> mdi-cellphone </v-icon>
                  </v-col>
                  <v-col class="align-self-center" cols="9">
                    {{ $t("loginWithPhone") }}
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="6" lg="7" xl="8" class="d-flex">
      <v-container align="center" class="align-self-center">
        <v-img :src="require('@/assets/general/loginBackground2.png')" />
      </v-container>
    </v-col>
  </v-row>
  <!-- </v-col> -->
</template> 

 <script>

/* 
  Name:Login
  Description: Login view is a page for the user login with the username, password, change password and login via google or email.

  Endpoints Functions Called:
  -AuthRepo
    login
*/

import firebase from "firebase";
import { mapActions } from "vuex";
import Repository from "@/services/Repository";

const AuthRepo = Repository.get("auth");

export default {
  name: "Login",
  async created() {
    await this.onLogout();
  },

  data() {
    return {
      loading: false,
      show: false,
      checkbox: false,
      username: null,
      password: null,
    };
  },
  computed: {},
  methods: {
    ...mapActions(["onLogout"]),
    async onLoginHandler() {
      try {
        this.loading = true;
        let res = await AuthRepo.login({
          username: this.username,
          password: this.password,
        });
        if (res) this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    googleSignIn() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(() => {
          alert("Successfully logged in with google");
          this.$router.push("/real_estate");
        })
        .catch((error) => {
          this.$store.dispatch("onSetSnackbar", {
            open: true,
            text: error.message,
            color: "error",
          });
        });
    },
    goToForgot() {
      this.$router.push("/forgotPassword");
    },
    goToRegister() {
      this.$router.push("/register");
    },
  },
};
</script> 

 <style scoped>
.loginContainer {
  /* width: 100%;
  height: 100vh; */
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(230, 235, 247, 1) 100%
  );
}
.btnContainer {
  text-transform: capitalize;
  font-size: 16px;
}

.btnContainerFullWidth {
  text-transform: capitalize;
  font-size: 15px;
  width: 100%;
}

.v-btn:not(.v-btn--round).v-size--large {
  height: 50px;
  min-width: 78px;
  padding: 0 19.5555555556px;
}

.btnBlack {
  background-color: #707070 !important;
  color: white !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  text-transform: unset !important;
  border-color: #707070 !important;
}

.v-text-field .v-label {
  font-size: 14px;
  font-family: CenturyGothic;
}
.checkboxAg .v-label {
  font-size: 15px;
  color: #43425d;
}
.forgetPass {
  font-family: CenturyGothic;
  font-size: 14px;
  color: #666666 !important;
}
.forgetPass:hover {
  color: rgb(119, 189, 134) !important;
}
@media screen and (max-width: 959px) {
  .loginContainer {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(230, 235, 247, 1) 100%
    );
  }
}

@media screen and (max-width: 1280px) {
  .btnContainer {
    width: 70%;
  }
}
</style> 
